<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            GameChanger
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to GameChanger! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please add OTP code the we sent to Your phone.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="OTP"
              label="OTP Code"
            >
              <validation-provider
                #default="{ errors }"
                name="OTP"
                :rules="{
                  required: true,
                  regex: /^[0-9]{5}$/,
                  length: 5,
                }"
              >
                <b-form-input
                  id="otp"
                  v-model="otp"
                  name="login-phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="12345"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Verifiy
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import Backendless from 'backendless'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      otp: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getCurrentUser()
  },
  methods: {
    async getCurrentUser() {
      console.log(Backendless)
      const currentUserObjectId = await Backendless.UserService.loggedInUser()
      console.log(currentUserObjectId)
      if (currentUserObjectId != null && currentUserObjectId != '') {
        const user = await Backendless.Data.of('Users').findById(currentUserObjectId)
        if (user != null) {
          // await Backendless.UserService.setCurrentUser(user, true);

          if (user.FirstName != null && user.FirstName != '') {
            // userName =
            //   "${mapOfCurrentUser['FirstName'][0].toUpperCase() + mapOfCurrentUser['FirstName'].substring(1)} ${mapOfCurrentUser['LastName'][0].toUpperCase() + mapOfCurrentUser['LastName'].substring(1)}";
            // avatarChars =
            //   "${mapOfCurrentUser['FirstName'][0].toUpperCase() + mapOfCurrentUser['LastName'][0].toUpperCase()}";
            // phone = user["phoneNumber"];
          }
        }
      }
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          console.log(`verify ${this.$route.params.id}`, this.otp)

          // api.backendless.com/1B87CFC9-DC13-690E-FFA2-F557A5EF0E00/84341BE0-BD91-4935-85BA-3E2D37B3029D/services/sendOtp/sendVerificationCode
          Backendless.APIServices.Twilio.loginWithCode(
            this.$route.params.id,
            this.otp,
            true,
          )
            .then(async loggedInUser => {
              const userdata = JSON.stringify(loggedInUser, null, 2)

              localStorage.setItem('userData', userdata)

              const isTokenValid = await Backendless.UserService.setCurrentUser(
                loggedInUser,
                true,
              )
              Backendless.LocalCache.set(
                Backendless.LocalCache.Keys.USER_TOKEN,
                loggedInUser['user-token'],
              )
              Backendless.LocalCache.set(
                Backendless.LocalCache.Keys.CURRENT_USER_ID,
                loggedInUser.objectId,
              )
              console.log('isTokenValid', isTokenValid)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Login Success',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push({
                name: 'home',
              })
            })
            .catch(e => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Login Faild :${e.message}`,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
